import { checkImageUrl } from "@helpers/helperAds";
import { getCurrencyByLanguage } from "@helpers/helperFormatCurrency";
import { useWhiteLabelLan } from "@hooks/useLayout";
import moment from "moment";
import Image from "next/image";

type RecentSearchesFlightType = {
  data: MainCardType;
  handleRedirectUrl: (
    urlObj: string | UrlLabelType | undefined,
    type: string
  ) => void;
};
export const FlightRS: React.FC<RecentSearchesFlightType> = ({
  data,
  handleRedirectUrl,
}) => {
  const { getLangLabel } = useWhiteLabelLan();
  const getDateTime = (date: string, element: "m" | "d") => {
    const momentDate = moment(date, "YYYY-MM-DD HH:mm:ss");

    if (momentDate.isValid()) {
      return element == "d"
        ? moment(date, "YYYY-MM-DD HH:mm:ss").date()
        : moment(date, "YYYY-MM-DD HH:mm:ss").month() + 1 < 10
        ? `0${moment(date, "YYYY-MM-DD HH:mm:ss").month() + 1}`
        : moment(date, "YYYY-MM-DD HH:mm:ss").month() + 1;
    }

    const dateCorrec = moment(date, "MM/DD/YY");
    const formattedDate = dateCorrec.format("YYYY/MM/DD");

    return element == "d"
      ? moment(formattedDate, "YYYY-MM-DD HH:mm:ss").date()
      : moment(formattedDate, "YYYY-MM-DD HH:mm:ss").month() + 1 < 10
      ? `0${moment(formattedDate, "YYYY-MM-DD HH:mm:ss").month() + 1}`
      : moment(formattedDate, "YYYY-MM-DD HH:mm:ss").month() + 1;
  };

  return (
    <div
      className="tw-relative tw-flex tw-bg-clip-border tw-mx-2 tw-rounded-xl tw-bg-white tw-text-gray-700 tw-shadow-[0px_0px_10px_0px_rgba(0,0,0,0.3)]  tw-drop-shadow-xl lg:tw-shadow-md tw-h-[134px] tw-w-[300px] tw-max-w-[300px] tw-flex-row tw-mb-1 tw-cursor-pointer "
      onClick={() => {
        handleRedirectUrl(data?.url, "flight");
      }}
    >
      <div className=" tw-relative tw-w-2/6 tw-m-0   tw-text-gray-700  tw-bg-white  tw-rounded-r-none  tw-bg-clip-border  tw-rounded-xl  tw-shrink-0 flex items-center tw-h-full ">
        <Image
          src={
            Array.isArray(data.image)
              ? checkImageUrl(data.image[0])
              : checkImageUrl(data.image)
          }
          alt="image"
          width={400}
          height={400}
          className="tw-h-full tw-rounded-l-lg "
          layout="fill"
          onError={(e) => {
            e.currentTarget.src = "/images/hotel-card/no-img-found.png";
          }}
        />
      </div>
      <div className=" tw-w-4/6 tw-pl-3 tw-pt-2 tw-pr-3">
        <div className="tw-min-h-16">
          <h6 className=" tw-text-lg tw-font-medium tw-text-gray-500 tw-w-full tw-relative [&>div]:hover:tw-visible">
            <div className=" title-ad-card tw-hover:text-clip tw-w-[85%] tw-block tw-text-[16px]/[15px] tw-font-sans  tw-antialiased  tw-font-bold  tw-leading-relaxed  tw-tracking-normal  tw-text-[#3f3f3f] tw-truncate tw-line-clamp-[1]">
              {`${data.departureName} ${data.title} ${getLangLabel("to")}`}
            </div>
            <div
              role="tooltip"
              className="tw-absolute tw-invisible tw-left-0 -tw-top-8 tw-z-10 tw-inline-block tw-px-3 tw-py-2 tw-text-sm tw-font-medium tw-text-gray-900 tw-bg-white tw-border tw-border-gray-200 tw-rounded-lg tw-shadow-sm"
            >
              {`${data.departureName} ${data.title} ${getLangLabel("to")}`}
            </div>
          </h6>
          <h4 className=" tw-relative tw-mb-0 tw-text-sm/[12px] tw-font-medium tw-text-[#757575] [&>div]:hover:tw-visible">
            <div className=" subtitle-ad-card tw-hover:text-clip tw-w-[85%] tw-block tw-text-[16px]/[15px] tw-font-sans  tw-antialiased  tw-font-bold  tw-tracking-normal  tw-text-[#3f3f3f] tw-truncate tw-line-clamp-[1]">
              {`${data.returnName} ${data.subtitle}`}
            </div>
            <div
              role="tooltip"
              className="tw-absolute tw-invisible tw-left-0 -tw-top-8 tw-z-10 tw-inline-block tw-px-3 tw-py-2 tw-text-sm tw-font-medium tw-text-gray-900 tw-bg-white tw-border tw-border-gray-200 tw-rounded-lg tw-shadow-sm"
            >
              {`${data.returnName} ${data.subtitle}`}
            </div>
          </h4>
        </div>
        <div className=" tw-flex tw-flex-row tw-mt-[-0.2rem] tw-size-1 tw-text-[13px]/[18px]  tw-font-bold">
          {data.cabinclass}
        </div>
        <div className=" tw-flex tw-flex-row tw-mt-3">
          <div className=" tw-w-[40%] tw-mt-1">
            <div className="tw-flex tw-w-100">
              <div className="tw-w-[100%] tw-text-[10px]/[18px] tw-text-gray-700 tw-font-medium">
                {getLangLabel("Departure")}
              </div>
            </div>
            <div className="tw-flex tw-w-[100%]  tw-text-left">
              <div className="tw-w-[100%] tw-text-[13px]/[18px] tw-text-gray-700 tw-font-bold">
                {`${getDateTime(String(data.dateDeparture), "m")}/${getDateTime(
                  String(data.dateDeparture),
                  "d"
                )}`}
              </div>
            </div>
          </div>
          <div className=" tw-w-[60%] tw-text-[#929292]  tw-font-medium tw-text-sm tw-flex tw-flex-row">
            <div className="tw-flex tw-flex-row tw-w-full tw-justify-between tw-items-start tw-align-sub">
              <div className="tw-text-[11px]/[18px] tw-text-[#6d6d6d] tw-font-normal tw-text-right"></div>
              <div className="tw-text-base tw-text-[#6d6d6d] tw-font-semibold tw-text-[16px] tw-leading-snug tw-flex tw-flex-col">
                <span className=" tw-text-[22px]">
                  {getCurrencyByLanguage(Number(data.price))}
                </span>
                <span className=" tw-text-[11px]/[4px] tw-text-[#6d6d6d] tw-font-normal tw-text-end tw-mt-1">
                  {`${data.persons} ${getLangLabel("passengers")}`}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
