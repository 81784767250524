import Image from "next/image";
import { classNames } from "@helpers/helper";
import { checkUrlType } from "@lib/ads";
import { checkImageUrl } from "@helpers/helperAds";

type BannerProps = {
  image: string;
  title: string;
  subTitle: string;
  offerTitle: string;
  stars: number;
  discount: number;
  score: number;
  checkinDate: string;
  checkOutDate: string;
  buttonUrl?: string | UrlLabelType;
};

export const HotelType1: React.FC<BannerProps> = ({
  image,
  title,
  subTitle,
  checkinDate,
  checkOutDate,
  buttonUrl,
  offerTitle,
}) => {
  const formatDate = (dateString: string) => {
    const date = new Date(dateString);
    const day = date.getUTCDate();
    const month = date.toLocaleString("default", { month: "short" });
    const year = date.getUTCFullYear().toString().slice(-2);
    return `${day} ${month} ${year}`;
  };

  const dateLabel = () => {
    if (!checkinDate || !checkOutDate) return "";
    return `${formatDate(checkinDate)} - ${formatDate(checkOutDate)}`;
  };
  return (
    <div
      className={classNames(
        "tw-relative tw-h-[367px] tw-cursor-pointer tw-rounded-2xl tw-w-[294px] tw-mx-2 tw-bg-white tw-text-[#525252] tw-shadow-xl",
        "hover:tw-shadow-2xl hover:tw-scale-105 tw-transform hover:-tw-translate-y-4 tw-transition-transform tw-duration-300 tw-ease-in-out tw-overflow-hidden"
      )}
      onClick={() => {
        checkUrlType(buttonUrl);
      }}
    >
      <Image
        src={
          Array.isArray(image) ? checkImageUrl(image[0]) : checkImageUrl(image)
        }
        alt="image"
        width={294}
        height={194}
        className="tw-rounded-t-2xl tw-w-full hover:tw-scale-110 tw-transition-transform tw-duration-300 tw-ease-in-out"
        layout="responsive"
      />
      <div className=" tw-bg-white tw-px-6 tw-pt-2 tw-flex tw-w-full tw-flex-col tw-h-[173px] tw-rounded-2xl">
        <div className="tw-relative tw-p-0 tw-m-0 tw-font-semibold tw-w-full [&>div]:hover:tw-visible">
          <h1 className="tw-truncate tw-max-h-8 tw-p-0 tw-m-0  tw-text-[22px] tw-w-full ">
            {title}
          </h1>
          <div
            role="tooltip1"
            className="tw-absolute tw-invisible tw-left-0 -tw-top-10 tw-z-10 tw-inline-block tw-px-3 tw-py-2 tw-text-sm tw-font-medium tw-text-gray-900 tw-bg-white tw-border tw-border-gray-200 tw-rounded-lg tw-shadow-sm"
          >
            {title}
          </div>
        </div>
        <div className=" tw-p-0 tw-m-0 tw-text-gray-500 tw-w-full tw-relative pt-1 [&>div]:hover:tw-visible">
          <p className="tw-text-ellipsis tw-overflow-hidden tw-text-[20px] tw-font-medium tw-h-16 tw-w-full ">
            {subTitle}
          </p>
          <div
            role="tooltip"
            className="tw-absolute tw-invisible tw-left-0 -tw-top-10 tw-z-10 tw-inline-block tw-px-3 tw-py-2 tw-text-sm tw-font-medium tw-text-gray-900 tw-bg-white tw-border tw-border-gray-200 tw-rounded-lg tw-shadow-sm"
          >
            {subTitle}
          </div>
        </div>
        <div className="  tw-flex tw-flex-row tw-justify-end tw-items-baseline tw-align-bottom">
          <div className="tw-flex tw-w-full tw-justify-end tw-flex-col">
            <span className=" tw-text-right tw-w-full tw-text-xl tw-font-bold">
              {offerTitle}
            </span>
            <span className=" tw-text-right tw-text-xs">{dateLabel()}</span>
          </div>
        </div>
      </div>
    </div>
  );
};
